import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/styles/App.css";

const Layout = React.lazy(() => import("./components/Layout/layout"));
const ReadMore = React.lazy(() => import("./components/About/readMore"));
const ProjectPage = React.lazy(() => import("./components/Projects/projectPage"));
const ExperiencePage = React.lazy(() => import("./components/Experience/experiencePage"));
const Loading = React.lazy(() => import("./components/Loading/loading"));

function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div>
            <Loading />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/about" element={<ReadMore />} />
          <Route path="/project/:projectName" element={<ProjectPage />} />
          <Route path="/experience/:experienceName" element={<ExperiencePage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
